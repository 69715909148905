import React from 'react';
// import Fade from 'react-reveal/Fade';
// import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer'

const Contact = () => {
    return (
        <section className="contact_section">
            <header className="content_header">
                <div className="header_logo_box">
                <nav style={{width:"100%"}} className="nav-container">
                    <ul style={{justifyContent:"flex-end"}} className="ui-container content-ui">
                    {/* <li className="nav-item"><button><Link to ="/">HOME</Link></button></li> */}
                    <li className="nav-item content_nav_li">HOME</li>
                    <li className="nav-item content_nav_li">ABOUT</li>
                    <li className="nav-item content_nav_li">SERVICES</li>
                    <li className="nav-item content_nav_li">WORKS</li>
                    <li className="nav-item content_nav_li">RECRUIT</li>
                    <li className="nav-item content_nav_li">BLOG</li>
                    <li className="nav-item content_nav_li">CONTACT</li>
                    </ul>
                </nav>
                </div>
                
            </header>
            <h2 className="contact-title-info">Contact Info</h2>
            <div className="container-container-form">
            <div className="contact-container-info">
                <div className="contact-container-item">
                    <p className="contact-address">Address</p>
                    <p className="contact-address-text">〒113-0033 東京都文京区本郷1-21-5 第四太平ビル2階</p>
                </div>
                <div className="contact-container-item">
                    <p className="contact-phone">Phone</p>
                    <p className="contact-phone-text">(03) 5615 8845</p>
                </div>
                <div>
                    <p className="contact-fax">Fax</p>
                    <p className="contact-fax-text">(03) 5615 8846</p>
                </div>
                <div className="contact-container-item">
                    <p className="contact-email">Email</p>
                    <p className="contact-email-text">contact@spin-dd.com</p>
                </div>
            </div>
                <div className="content-middle-container">        
                    <form>
                        <div className="contact-form-d-flex">
                        <div className="contact-form--container-left">
                            <h6>お名前</h6>
                            <input type="text" placeholder="名前" />
                            <h6>Email</h6>
                            <input type="email" placeholder="(例) spin-dd@spin .com" />
                            <h6>電話番号</h6>
                            <input type="tel" placeholder="(例) 090-0000-1111" />
                        </div>
                        <div className="contact-form--container-right">
                            <textarea className="contact-textarea" name="" id="" cols="30" rows="10" placeholder="メッセージ内容"></textarea>
                        </div>
                        </div>
                        <a href="/" class="submit">送信</a>
                    </form>
                </div>
            </div>
            <iframe className="g_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.11088509294!2d139.75319863669995!3d35.704991103902955!2m3!1f0!2f0!3f0!3m2!1i1024!2widi768!4f13.1!3m3!1m2!1s0x6019135d2599a6bf%3A0x7a43f5f7989ca678!2z5qCq5byP5Lya56S-U1BJTiBEJkQ!5e0!3m2!1sja!2sjp!4v1608361266432!5m2!1sja!2sjp" width="100%" height="400px" >mfkdsmakdl</iframe>
            <Footer />
        </section>
    )
}

export default Contact;