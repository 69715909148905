import React from 'react';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import About from './About/About';

const AboutPage = () => {
    return (
        // <Layout>
        <>
        <SEO title="All posts | About" />
            <About />
        </>
        // </Layout>
    );
};
export default AboutPage